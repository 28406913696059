import { Routes } from '@angular/router';
import { AppPathSetting } from './app-path';
import { PageErrorComponent } from './page/page-error/page-error.component';
import { PageLoginComponent } from './page/page-login/page-login.component';
import { authGuard } from './guards/auth.guard';
import { PrivacyPolicyWebComponent } from './page/page-privacy-policy/privacy-policy-web/privacy-policy-web.component';
import { PrivacyPolicyGoyoungComponent } from './page/page-privacy-policy/privacy-policy-goyoung/privacy-policy-goyoung.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: AppPathSetting.home.path,
        pathMatch: 'full',
    },
    {
        path: AppPathSetting.home.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-index/page-index.module').then(m => m.PageIndexModule),
    },
    {
        path: AppPathSetting.about.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-about/page-about.module').then(m => m.PageAboutModule),
    },
    {
        path: AppPathSetting.showcase.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-showcase/page-showcase.module').then(m => m.PageShowcaseModule),
    },
    {
        path: AppPathSetting.investor.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-investor/page-investor.module').then(m => m.PageInvestorModule),
    },
    {
        path: AppPathSetting.governance.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-governance/page-governance.module').then(m => m.PageGovernanceModule),
    },
    {
        path: AppPathSetting.urban.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-urban/page-urban.module').then(m => m.PageUrbanModule),
    },
    {
        path: AppPathSetting.contact.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        loadChildren: () => import('./page/page-contact/page-contact.module').then(m => m.PageContactModule),
    },
    {
        path: AppPathSetting.member.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        canActivate: [authGuard],
        loadChildren: () => import('./page/page-member/page-member.module').then(m => m.PageMemberModule),
    },
    {
        path: AppPathSetting.login.path,
        component: PageLoginComponent,
    },   
    {
        path: AppPathSetting.privacy.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        component: PrivacyPolicyWebComponent,
    },
    {
        path: AppPathSetting.privacy_goyoung.path,
        data: {
            breadcrumb: null,
            breadcrumb_EN: null,
        },
        component: PrivacyPolicyGoyoungComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '',
        //component: PageErrorComponent,
    }
];
