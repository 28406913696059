import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtService } from '../service/util-services/jwt.service';

export const authGuard: CanActivateFn = (route, state) => {
  const jwtService = inject(JwtService);
  const router = inject(Router);

  const isLocalStorageNotAvailable = typeof localStorage == 'undefined';
  if (isLocalStorageNotAvailable) {
    return false;
  }


  if (!jwtService.isTokenExpired()) {
    return true;
  } else {
    router.navigate(['/login'], { queryParams: { returnUrl: state.url } }); // 可選: 將當前 URL 傳遞給登入頁
    return false;
  }
};
