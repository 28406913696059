import { Component } from '@angular/core';
import { MainContainerComponent } from '../../../common/asnt-content/main-container/main-container.component';
import { PageInnerStructureModule } from '../../../common/asnt-content/page-inner-structure/page-inner-structure.module';
import { AppPathSetting } from '../../../app-path';
import { PageInfoService } from '../../../service/page-info.service';

@Component({
  selector: 'privacy-policy-goyoung',
  standalone: true,
  imports: [
    PageInnerStructureModule,
    MainContainerComponent,
  ],
  templateUrl: './privacy-policy-goyoung.component.html',
  styleUrl: './privacy-policy-goyoung.component.less'
})
export class PrivacyPolicyGoyoungComponent {

  pageType: string = AppPathSetting.privacy_goyoung.pageType;


  constructor(
    private pageInfoService: PageInfoService,
  ) {

  }

  ngOnInit() {
    this.pageInfoService.setPageType(this.pageType);
  }

}
