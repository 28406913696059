<div [classList]="'asnt-forget-panel-inner fadeIn ' + deviceInfo">

  <div class="main-panel">

    <div class="asnt-forget-form">
      <div class="form-title">
        忘記密碼
        <div class="close icon">
          <mat-icon class="material-symbols-outlined" mat-dialog-close>close</mat-icon>
        </div>
      </div>
      <div class="form-content">
        <div class="form-id">
          <mat-form-field appearance="fill">
            <mat-label>會員帳號</mat-label>

            <input matInput [formControl]="account" required />
            <mat-icon matPrefix class="material-symbols-outlined">assignment_ind</mat-icon>
          </mat-form-field>
        </div>
        <div class="form-information">
          <span>{{ saleInfo.Message }}</span>
          <div class="email_phone">
            <span>
              e-mail: <a href="mailto:{{ saleInfo.Email }}?subject=忘記密碼">{{ saleInfo.Email }}</a>
            </span>
            <span>電話: {{ saleInfo.Phone }}</span>
          </div>
        </div>

        <div class="form-submit">
          <div class="form-submit-button confirm" (click)="confirmClick();" [ngClass]="{'disabled': (submit$ | async)}">
            <div class="icon">
              @if (submit$ | async) {
                <img [src]="'../../../assets/images/loading/loading.png'" alt="loading">
              }
              @else {
                <mat-icon class="material-symbols-outlined">send</mat-icon>
              }
            </div>
            <span>送出</span>
          </div>
          <div class="form-submit-button cancel" mat-dialog-close>
            <div class="icon">
              <mat-icon class="material-symbols-outlined">undo</mat-icon>
            </div>
            <span>返回</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>