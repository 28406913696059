import { CommonModule } from '@angular/common';
import { Component, HostBinding, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';
import { LoadTimerService } from '../../service/load-timer.service';
import { imgPath } from '../../app-path';
import { environment } from '../../../environments/environment';
import { LoginService } from '../../service/api/login.service';
import { EncriptService } from '../../service/util-services/encript.service';
import { lastValueFrom } from 'rxjs';
import { User } from '../../model/user.model';
import { JwtService } from '../../service/util-services/jwt.service';
import { MatDialog } from '@angular/material/dialog';
import { AsntForgetPasswordPanelComponent } from '../asnt-forget-password-panel/asnt-forget-password-panel.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'asnt-login-panel',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  templateUrl: './asnt-login-panel.component.html',
  styleUrl: './asnt-login-panel.component.less'
})
export class AsntLoginPanelComponent {

  passwordVisible: boolean = false;
  deviceInfo: string = 'Unknown';
  imagePath = imgPath.imageSrc;
  isLoginLoading: boolean = false; // 控制是否顯示登入loading畫面
  displayMainLoading: boolean = environment.displayMainLoading; //如果是開發環境就不顯示Loading
  panelFadeIn: boolean = false; // 控制是否等待Loading後才顯示動畫

  account: string = '';
  password: string = '';
  returnUrl: string = '';

  @HostBinding('class') get hostClasses(): string {
    return 'asnt-login-panel';
  }

  private snack = inject(MatSnackBar);
  constructor(
    private router: Router,
    private loadTimerService: LoadTimerService,
    private customDeviceService: CustomDeviceDetectService,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private encriptService: EncriptService,
    private jwtService: JwtService,
  ) {
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });

    //如果開啟主畫面loading
    if (this.displayMainLoading) {
      //就監聽主畫面loading; this.panelFadeIn就會等loading結束還才跑動畫
      this.loadTimerService.getMainLoadStatus().subscribe((isLoading) => {
        this.panelFadeIn = isLoading;
      });
    } else {
      //否則就直接顯示
      this.panelFadeIn = this.displayMainLoading;
    }

    // 取得redirectUrl
    this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
  }


  // 點擊事件
  async onLoginClick() {
    if (this.account === '' || this.password === '') {
      return;
    }
    this.isLoginLoading = !this.isLoginLoading;

    const enc = await this.encriptService.encryptData(this.password, this.account);
    const user: User = {
      UserId: this.account,
      Password: enc,
    }
    const loginToken = await lastValueFrom(this.loginService.login(user)).catch(
      (e) => {
        this.snack.open(e['error']['Message'], '', { duration: 3000 });
        return null;
      }
    ).finally(() => this.isLoginLoading = !this.isLoginLoading);

    if (loginToken) {
      this.jwtService.writeToken(loginToken);

      if (!this.jwtService.isTokenExpired()) {
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
        }
        else {
          this.router.navigate(['/member/payment']);
        }
      }
    }
  }

  passwordVisibleToggle(): void {
    this.passwordVisible = !this.passwordVisible;
  }

  private dialog: MatDialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(AsntForgetPasswordPanelComponent, {
      // data: {},
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
