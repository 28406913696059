import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor() { }

  ///////////// local Storage /////////////
  setJsonData_Local(key: string, value: any) {
    // 跨越tab的類型，service的變數會沒辦法帶到新tab
    localStorage.setItem(key, JSON.stringify(value));
  }

  getJsonDataByKey_Local(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  setData_Local(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getDataByKey_Local(key: string): string {
    return localStorage.getItem(key);
  }

  removeData_Local(key: string) {
    if (this.getDataByKey_Local(key)) {
        localStorage.removeItem(key);
    }
  }

  ///////////// Session Storage /////////////
  setJsonData_Session(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getJsonDataByKey_Session(key: string): any {
    return JSON.parse(sessionStorage.getItem(key))
  }

  getDataByKey_Session(key: string): any {
    return sessionStorage.getItem(key)
  }

  removeData_Session(key: string) {
    if (this.getDataByKey_Session(key)) {
      sessionStorage.removeItem(key);
    }
  }
}
