import { Component } from '@angular/core';
import { MainContainerComponent } from '../../../common/asnt-content/main-container/main-container.component';
import { PageInnerStructureModule } from '../../../common/asnt-content/page-inner-structure/page-inner-structure.module';
import { AsntMainVisualComponent } from '../../../common/asnt-main-visual/asnt-main-visual.component';
import { AsntPgaeBasedComponent } from '../../../common/asnt-pgae-based/asnt-pgae-based.component';
import { AppPathSetting } from '../../../app-path';
import { PageInfoService } from '../../../service/page-info.service';
import { Title } from '@angular/platform-browser';

@Component({
  standalone: true,
  imports: [
    MainContainerComponent,
    PageInnerStructureModule,
    AsntMainVisualComponent,
  ],
  selector: 'privacy-policy-web',
  templateUrl: './privacy-policy-web.component.html',
  styleUrl: './privacy-policy-web.component.less'
})
export class PrivacyPolicyWebComponent extends AsntPgaeBasedComponent {

  constructor(
    pageInfoService: PageInfoService,
    private titleService: Title,
  ) {
    super(pageInfoService);
    this.pageType = AppPathSetting.privacy.pageType;
    this.pageOrder = AppPathSetting.privacy.order;
    this.pageTitle_CH = AppPathSetting.privacy.name_CH;
    this.pageTitle_EN = AppPathSetting.privacy.name_EN;
    this.pageDescription = AppPathSetting.privacy.meta_descp;
    this.pageKeyword = AppPathSetting.privacy.meta_keyword;
    this.pageKeyVisual = AppPathSetting.privacy.kv;
    this.breadcrumbClassA = [
      AppPathSetting.privacy.name_CH, ''
    ];
    //Page Title 設定
    this.titleService.setTitle(this.pageTitle_CH + ' - ' + this.webSiteName);
  }

}
