import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, interval, map, Observable } from 'rxjs';
import { JwtTokenLocation } from '../../app.config';
import { LocalStorageService } from './local-storage.service';

export interface JwtPayload {
  nameid: number,
  acctype: number,
  iss: string,
  iat: string,
  name: string,
  title: string,
  exp: string,
}

@Injectable({
  providedIn: 'root'
})

export class JwtService {
  isLogin$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private decodePayload: JwtPayload;

  private jwtHelper: JwtHelperService;
  private localStorageService: LocalStorageService;
  constructor(private injector: Injector) {
    this.jwtHelper = injector.get(JwtHelperService);
    this.localStorageService = injector.get(LocalStorageService);
  }

  // 每一秒檢查一次token是否失效
  validate(): Observable<any> {
    return interval(1000).pipe(
      map(() => {
        return !this.isTokenExpired();
      })
    );
  }

  isTokenExpired(key: string = JwtTokenLocation): boolean {
    if (typeof window !== 'undefined' && window.localStorage) {
      const jwtStr = this.localStorageService.getDataByKey_Local(key);
      if (jwtStr) {
        const isExpired = this.jwtHelper.isTokenExpired(jwtStr);
        this.isLogin$.next(!isExpired);
        return isExpired;
      } else {
        this.isLogin$.next(false);
        return true;
      }
    }
    return true;
  }

  getTokenPayload(key: string = JwtTokenLocation): JwtPayload {
    if (typeof window !== 'undefined' && window.localStorage) {
      if (this.decodePayload !== null && this.decodePayload !== undefined) {
        return this.decodePayload;
      } else {
        if (!this.isTokenExpired()) {
          const jwtStr = this.localStorageService.getDataByKey_Local(key);
          return this.jwtHelper.decodeToken(jwtStr);
        } else {
          return null;
        }
      }
    }
    return null;
  }

  getTokenPayloadByCol(column: string, key: string = JwtTokenLocation) {
    if (typeof window !== 'undefined' && window.localStorage) {
      const payload: JwtPayload = this.getTokenPayload(key);

      if (payload) {
        if (payload[column] != null) {
          return payload[column];
        }
      }

      return '';
    }
    return '';
  }

  getToken(key: string = JwtTokenLocation): string {
    if (typeof window !== 'undefined' && window.localStorage) {
      return this.localStorageService.getDataByKey_Local(key);
    }
    return '';
  }

  getToken_AuthorizationBear(key: string = JwtTokenLocation): string {
    if (typeof window !== 'undefined' && window.localStorage) {
      const loginToken = this.localStorageService.getDataByKey_Local(key);
      const jwtToken = loginToken ? loginToken : '';
      return jwtToken;
    }
    return '';
  }

  writeToken(jwtString: string, key: string = JwtTokenLocation) {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.decodePayload = this.jwtHelper.decodeToken(jwtString);
      this.localStorageService.setData_Local(key, jwtString);
      this.isLogin$.next(this.jwtHelper.isTokenExpired(jwtString));
    }
  }

  removeToken(key: string = JwtTokenLocation) {
    if (typeof window !== 'undefined' && window.localStorage) {
      this.localStorageService.removeData_Local(key);
      this.isLogin$.next(false);
    }
  }
}
