<div [classList]="'asnt-login-panel-inner ' + deviceInfo"
    [ngClass]="{'fadeIn': panelFadeIn === false, 'isLogin': isLoginLoading,}">

    <div class="main-panel">

        <div class="asnt-login-form">
            <!-- <div class="form-title">雋揚會員登入</div> -->
            <div class="form-content">
                <div class="form-id">
                    <mat-form-field appearance="fill">
                        <mat-label>會員帳號</mat-label>

                        <input matInput [(ngModel)]="account" required />
                        <mat-icon matPrefix class="material-symbols-outlined">assignment_ind</mat-icon>
                        <mat-hint>Hint</mat-hint>
                    </mat-form-field>
                </div>
                <div class="form-password">

                    <mat-form-field appearance="fill">
                        <mat-label>會員密碼</mat-label>
                        <input [type]="passwordVisible ? 'text' : 'password'" matInput [(ngModel)]="password" required />
                        <mat-icon matPrefix class="material-symbols-outlined">password</mat-icon>
                    </mat-form-field>

                    <div class="password-visibility" (click)="passwordVisibleToggle()">

                        @if (!passwordVisible) {
                        <mat-icon matsuffix class="material-symbols-outlined">visibility</mat-icon>
                        } @else {
                        <mat-icon matsuffix class="material-symbols-outlined">visibility_off</mat-icon>
                        }

                    </div>
                </div>
                <div class="form-remember-id-forget-password">
                    <div class="st">
                        <!-- 記住帳號 -->
                    </div>
                    <div class="nd">
                        <span (click)="openDialog()">
                            忘記密碼
                        </span>
                    </div>
                </div>
                <div class="form-google-recaptcha"></div>
                <div class="form-submit">
                    <div class="form-submit-button" (click)="onLoginClick();">
                        <div class="icon">
                            <mat-icon class="material-symbols-outlined">login</mat-icon>

                        </div>
                        <span>登入</span>
                    </div>
                </div>
            </div>
        </div>
    </div>



</div>

@if(isLoginLoading) {
<div class="login-load">
    <div class="login-load-img">
        <img [src]="imagePath + 'loading/loading.png'" alt="loading">
    </div>
    <div class="login-load-text">登入中</div>
</div>

}