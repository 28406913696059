import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withDebugTracing } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import { FormsModule } from '@angular/forms';
import { provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { JwtModule } from '@auth0/angular-jwt';

registerLocaleData(zh);

// const scrollConfig: InMemoryScrollingOptions = {
//   scrollPositionRestoration: 'top',
//   anchorScrolling: 'enabled',
// };

// const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {

  providers: [
    provideRouter(routes, withComponentInputBinding()),
    // provideRouter(routes, withDebugTracing()), // 啟用路由追蹤
    provideClientHydration(),
    provideAnimationsAsync(),
    // provideNzI18n(zh_TW),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    provideHttpClient(),
    importProvidersFrom(
      JwtModule.forRoot({
        config: {
          tokenGetter: () => {
            return localStorage.getItem(environment.accessTokenName);
          },
        },
      }),
    ),
  ]

};

export const JwtTokenLocation = environment.accessTokenName;