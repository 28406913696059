<asnt-main-visual></asnt-main-visual>

<main-container>
    <page-container>
        <div id="privacy-policy-web">

            <div class="privacy-container">

                <p>雋揚國際股份（以下簡稱「雋揚」）遵循本隱私權政策（以下簡稱「本政策」）蒐集、處理、使用及保護您所提供之個人資料及非個人資料。請務必詳細閱讀本政策。</p>


                <h3>資料種類</h3>
                <p>「非個人資料」係指單獨存在時無法識別個人身分之資料，包含性別、年齡、語言、興趣、瀏覽器類型及產品資訊等。雋揚可能蒐集或記錄這些資訊，但雋揚不會將這些資訊用來辨識您的身份，或將這些資訊與您的個人資料做比對。若有必要將非個人資料與個人資料結合的情形，該結合之資料亦為「個人資料」。
                </p>
                <p>「敏感性資料」係指醫療或健康檢查紀錄、政治傾向、宗教信仰、犯罪記錄、基因、性取向及性生活等。</p>
                <p>「個人資料」係指可直接或間接識別個人身分之資料，包含姓名、出生年月日、電話、電子郵件、帳號資訊、身分證字號、信用卡資料、所在位址、IP位址等。</p>

                <h3>資料之蒐集</h3>
                <p>雋揚原則上不會要求您必須提供個人資料才能瀏覽或使用雋揚的官方網站或App，但在您使用特定服務，如訂購商品、員工招募、尋求客戶服務、提出客戶意見、協助客戶滿意度調查、參加雋揚所舉辦之活動等時，雋揚則可能會要求您提供個人資料作為身分確認與提供服務之用。您保有不提供個人資料予雋揚之權利，亦保有同意提供個人資料後撤回該同意之權利。
                </p>
                <p>雋揚亦有可能從代銷公司、廣告公司等處取得您的個人資料，雋揚並將依照本政策對您的個人資料進行處理、使用及保護。</p>

                <h3>資料之處理及使用</h3>
                <p>除了向您提供服務以及依法律規定、主管機關、司法單位或為保全法律上的請求、抗辯或為防止詐騙等其他一切不法行為所必要之外，雋揚不會處理或使用您的個人資料，亦不會對外販售、交換或出租您的個人資料。</p>

                <h3>資料之移轉處理</h3>
                <p>當您將個人資料提供予雋揚，您已了解並同意該部分個人資料將會在雋揚的伺服器間進行傳輸。您可以要求查看雋揚所儲存的您的個人資料。資料會以有組織的、通用的以及電腦可閱讀的格式提供。</p>

                <h3>您的權益</h3>
                <p>就雋揚所處理的您的個人資料，您有權要求雋揚提供以下資訊：</p>
                <ul>
                    <li>個人資料之處理目的；</li>
                    <li>被處理的個人資料種類；</li>
                    <li>已揭露或正在揭露之個人資料接收者或接收者類型；</li>
                    <li>個人資料將被儲存之預期期間，如無法告知期間，則應告知該資料儲存期間之確定標準；</li>
                    <li>請求雋揚更正、刪除您的個人資料之權利，或限制、拒絕雋揚處理您的個人資料之權利；</li>
                    <li>向監管機關提出申訴之權利；</li>
                    <li>若雋揚是透過您以外之其他來源蒐集您的個人資料，關於該來源之任何資訊；</li>
                </ul>

                <h3>對於個人資料所得主張之權利</h3>
                <p>透過連絡雋揚，您可以查看並編輯您的個人資料與非個人資料，以確保您的資料正確性。為了資料的完整性與修正更新，雋揚有權記錄您對於資料所做的變更。</p>
                <p>為了確保您的個人資料受到充分的管理與保護，請注意以下事項：</p>

                <ul>
                    <li>您有權決定您的個人資料是否被蒐集、處理以及使用。</li>
                    <li>透過連絡雋揚，您有權隨時審視、查看以及變更您的個人資料。</li>
                    <li>就雋揚所儲存之您的個人資料，您有權要求提供乙份複製本。</li>
                    <li>若您的個人資料儲存期間並無法律依據，你有權要求雋揚刪除該部分個人資料。</li>
                    <li>就您所收到有關於您的資料，您有權要求該部分資料係以有組織的、通用的以及電腦可閱讀的格式製作。</li>
                    <li>您有權隨時撤回您對於資料提供之同意。</li>
                </ul>

                <p>您可以透過本政策末段所記載之資訊連絡雋揚，以獲得本政策或相關服務之進一步資訊。</p>

                <h3>網站安全</h3>
                <p>為維護您的資料安全，雋揚將嚴格執行本政策，並採取適當之安全措施防止您的個人資料被竊取、竄改或毀損，惟無法保證網路安全。請務必妥善保管您的密碼，並定期更換之，另密碼設定應避免過於簡單，且請您隨時更新您電腦上的防毒及安全防護軟體。
                </p>

                <h3>第三人網站</h3>
                <p>本網站可能含有連向第三人網站之連結或使用第三人所提供之服務，您了解該網站與服務之資料處理不適用本政策，雋揚亦不對該網站內容負任何責任。</p>


                <h3>未成年人隱私權</h3>
                <p>雋揚不會在知情且未取得父母或監護人同意的情況下，蒐集未成年人的個人資料。如雋揚不慎取得，經他方通知或自行發現後，雋揚將移除該資料。</p>

                <h3>隱私權政策之變更</h3>
                <p>雋揚將不定期更新本政策，請以本網站公佈之內容為準。</p>

                <h3>連絡雋揚</h3>
                <p>若對本政策有任何疑問或建議，您可以透過以下方式連絡雋揚</p>

                <div class="contact">
                    雋揚國際股份有限公司<br>
                    11072 台北市信義區忠孝東路四段557之1號19樓<br>
                    886 2 2756 6777 ext.1658<br>
                    <a href="mailto:adm@ascentglobal.com.tw">聯絡信箱</a>
                </div>
            </div>

        </div>
    </page-container>
</main-container>