import { Component, inject } from '@angular/core';
import { AsntMainVisualComponent } from '../../common/asnt-main-visual/asnt-main-visual.component';
import { AsntPgaeBasedComponent } from '../../common/asnt-pgae-based/asnt-pgae-based.component';
import { AppPathSetting } from '../../app-path';
import { PageInfoService } from '../../service/page-info.service';
import { AsntLoginPanelComponent } from '../../common/asnt-login-panel/asnt-login-panel.component';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'page-login',
  standalone: true,
  imports: [
    AsntMainVisualComponent,
    AsntLoginPanelComponent,
  ],
  templateUrl: './page-login.component.html',
  styleUrl: './page-login.component.less'
})
export class PageLoginComponent extends AsntPgaeBasedComponent {

  constructor(
    pageInfoService: PageInfoService,
    private titleService: Title,
  ) {
    super(pageInfoService);
    this.pageType = AppPathSetting.login.pageType;
    this.pageOrder = AppPathSetting.login.order;
    this.pageTitle_CH = AppPathSetting.login.name_CH;
    this.pageTitle_EN = AppPathSetting.login.name_EN;
    this.pageKeyVisual = AppPathSetting.login.kv;
    this.breadcrumbClassA = [
      AppPathSetting.login.name_CH, '/' + AppPathSetting.login.path
    ];
    //Page Title 設定
    this.titleService.setTitle(this.pageTitle_CH + ' - ' + this.webSiteName);
  }
}
