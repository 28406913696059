<div id="privacy-policy-goyoung">

    <div class="privacy-container">

        <div class="logo">
            <img class="goy-logo" src="/assets/images/goy/goy-logo-202405.svg" />
        </div>

        <div class="topic">隱私權政策</div>

        <p>
            GoYoung是由雋揚國際股份(Ascent Development CO.,
            LTD.)（以下稱「雋揚」）所開發營運，提供國揚集團旗下公司經營資訊發布、意見調查與活動舉辦之公關平台服務（以下稱「本服務」）。
            本公司非常重視您的隱私權，並致力保護您的個人資料，為了提供您安心使用的環境，制定本隱私權政策（以下稱「本政策」）。雋揚遵本政策蒐集、處理、使用及保護您所提供之個人資料及非個人資料。
            請您務必於使用本服務前，確認您已審閱並同意本政策所列之全部條款，一旦您使用本服務，即表示您同意遵守本政策，與任何不定時提供給您的更新政策及條款。當您於本政策修改或變更後繼續使用本服務時，將視為您已閱讀、瞭解並同意接受該等修改或變更。若不同意上述的本政策修訂或更新方式，或不接受本政策的任一條約定，請您立即停止使用本服務。
        </p>


        <h3>適用範圍</h3>
        <p>「本政策保障及適用範圍包括您在使用本服務時所提供之「個人資料」與「非個人資料」。</p>


        <h3>適用範圍</h3>
        <p>本政策保障及適用範圍包括您在使用本服務時所提供之「個人資料」與「非個人資料」。</p>


        <h3>資料種類</h3>
        <p>「非個人資料」係指單獨存在時無法識別個人身分之資料，包含性別、年齡、語言、連線設備類型及瀏覽器類型等。雋揚可能蒐集或記錄這些資訊，但雋揚不會將這些資訊用來辨識您的身份，或將這些資訊與您的個人資料做比對。若有必要將非個人資料與個人資料結合的情形，該結合之資料亦為「個人資料」。
        </p>
        <p>「敏感性資料」係指醫療或健康檢查紀錄、政治傾向、宗教信仰、犯罪記錄、基因、性取向及性生活等。</p>
        <p>「個人資料」係指可直接或間接識別個人身分之資料，包含姓名、出生年月日、電話、電子郵件、帳號資訊、身分證字號、信用卡資料、所在位址、IP位址等。</p>


        <h3>蒐集資料之目的</h3>
        <p>本服務得依下列目的之需要蒐集、使用、揭露或處理您的「個人資料」與「非個人資料」：</p>
        <ul>
            <li>為排除技術問題或優化使用者體驗</li>
            <li>為提供國揚集團旗下公司發布消息、蒐集意見或邀請您參與活動</li>
            <li>當您參加國揚集團旗下公司活動而獲得獎品時，使用您的個人資料與您聯絡領獎事宜</li>
            <li>其他取得您的同意之目的</li>
            <li>其他與上述目的相關之目的</li>
        </ul>
        <p>雋揚不會要求您提供敏感性資料，同時也要求您不要提供這樣的資料。如果您仍決定提供這些資料，雋揚將依照本政策處理。</p>



        <h3>資料之揭露</h3>
        <p>除了向您提供服務以及依法律規定、主管機關、司法單位或為保全法律上的請求、抗辯或為防止詐騙等其他一切不法行為所必要之外，雋揚不會揭露您的個人資料，亦不會對外販售、交換或出租您的個人資料。</p>


        <h3>資料之移轉處理</h3>
        <p>當您將個人資料提供予雋揚，您已了解並同意該部分個人資料將會在雋揚的伺服器間進行傳輸。您可以要求查看雋揚所儲存的您的個人資料。資料會以有組織的、通用的以及電腦可閱讀的格式提供。</p>


        <h3>您的權益</h3>
        <p>就雋揚所處理的您的個人資料，您有權要求雋揚提供以下資訊：</p>
        <ul>
            <li>個人資料之處理目的；</li>
            <li>被處理的個人資料種類；</li>
            <li>已揭露或正在揭露之個人資料接收者或接收者類型；</li>
            <li>個人資料將被儲存之預期期間，如無法告知期間，則應告知該資料儲存期間之確定標準；</li>
            <li>請求雋揚更正、刪除您的個人資料之權利，或限制、拒絕雋揚處理您的個人資料之權利；</li>
            <li>向監管機關提出申訴之權利；</li>
            <li>若雋揚是透過您以外之其他來源蒐集您的個人資料，關於該來源之任何資訊；</li>
        </ul>


        <h3>對於個人資料所得主張之權利</h3>
        <p>透過使用本服務，您可以查看並編輯您的個人資料與非個人資料，以確保您的資料正確性。為了資料的完整性與修正更新，雋揚有權記錄您對於資料所做的變更。</p>
        <p>為了確保您的個人資料受到充分的管理與保護，請注意以下事項：</p>
        <ul>
            <li>您有權決定您的個人資料是否被蒐集、處理以及使用。</li>
            <li>透過使用本服務，您有權隨時審視、查看以及變更您的個人資料。</li>
            <li>透過連絡雋揚，就雋揚所儲存之您的個人資料，您有權要求提供乙份複製本。</li>
            <li>若您的個人資料儲存期間並無法律依據，你有權要求雋揚刪除該部分個人資料。</li>
            <li>就您所收到有關於您的資料，您有權要求該部分資料係以有組織的、通用的以及電腦可閱讀的格式製作。</li>
            <li>您有權隨時撤回您對於資料提供之同意。</li>
        </ul>
        <p>您可以透過本政策末段所記載之資訊連絡雋揚，以獲得本政策或相關服務之進一步資訊。</p>



        <h3>網站安全</h3>
        <p>為維護您的資料安全，雋揚將嚴格執行本政策，並採取適當之安全措施防止您的個人資料被竊取、竄改或毀損，惟無法保證網路安全。請務必妥善保管您的密碼，並定期更換之，另密碼設定應避免過於簡單，且請您隨時更新您連線設備上的防毒及安全防護軟體。
        </p>


        <h3>第三人網站</h3>
        <p>本服務可能含有連向第三人網站之連結或使用第三人所提供之服務，您了解該網站與服務之資料處理不適用本政策，雋揚亦不對該網站內容負任何責任。</p>


        <h3>未成年人隱私權</h3>
        <p>雋揚不會在知情且未取得父母或監護人同意的情況下，蒐集未成年人的個人資料。如雋揚不慎取得，經他方通知或自行發現後，雋揚將移除該資料。</p>


        <h3>隱私權政策之變更</h3>
        <p>雋揚將不定期更新本政策，請以本網頁公佈之內容為準。</p>


        <h3>連絡雋揚</h3>
        <p>若對本政策有任何疑問或建議，您可以透過以下方式連絡雋揚</p>

        <div class="contact">
            雋揚國際股份有限公司<br>
            11072 台北市信義區忠孝東路四段557之1號19樓<br>
            886 2 2756 6777 ext.1658<br>
            <a href="mailto:adm@ascentglobal.com.tw">聯絡信箱</a>
        </div>
    </div>

</div>