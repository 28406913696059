import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { imgPath } from '../../app-path';
import { ContactSalesInfo, User } from '../../model/user.model';
import { LoginService } from '../../service/api/login.service';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';
import { LoadTimerService } from '../../service/load-timer.service';
import { EncriptService } from '../../service/util-services/encript.service';
import { JwtService } from '../../service/util-services/jwt.service';
import { AsntLoginPanelComponent } from '../asnt-login-panel/asnt-login-panel.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'asnt-forget-password-panel',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatDialogModule,
  ],
  templateUrl: './asnt-forget-password-panel.component.html',
  styleUrl: './asnt-forget-password-panel.component.less'
})
export class AsntForgetPasswordPanelComponent implements OnInit {

  passwordVisible: boolean = false;
  deviceInfo: string = 'Unknown';
  imagePath = imgPath.imageSrc;

  account: FormControl = new FormControl('');
  saleInfo: ContactSalesInfo

  submit$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private loginService = inject(LoginService);
  private snack = inject(MatSnackBar);
  constructor(
    private customDeviceService: CustomDeviceDetectService,
  ) {
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });
  }

  async ngOnInit() {
    this.saleInfo = await lastValueFrom(this.loginService.getSalesInfo());
  }

  // 點擊事件
  async confirmClick() {
    if (this.submit$.getValue() || this.account.value === '') {
      return;
    }

    this.submit$.next(true);
    const result = await lastValueFrom(this.loginService.forgetPassword(this.account.value)).finally(() => this.submit$.next(false));
    if (result) {
      this.snack.open('已聯繫專人協助處理', '', { duration: 3000 });
      this.account.reset();
    }
  }
}
