import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base-service';
import { ContactSalesInfo, User } from '../../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  constructor(protected override injector: Injector) {
    super(injector);
  }

  login(user: User) {
    return this.http.post<string>(this.ApiUrl.login, user, this.JsonTypeHeader);
  }

  getSalesInfo() {
    return this.http.get<ContactSalesInfo>(this.ApiUrl.getSalesInfo, this.JsonTypeHeader);
  }

  forgetPassword(userId: string) {
    const req = {
      userId
    };

    return this.http.post<boolean>(this.ApiUrl.forgetPassword, null, this.createHttpParams(req));
  }

}
